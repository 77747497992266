import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export default class Allergens extends Component{
    render(){
   
     
       return(
        <> 
      
      <div className="section-reservation-form section_padding">
      <div className="row p-btm">
          <div className="container">
            <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="swin-sc swin-sc-title style-2">
                    <h3 className="title swin-sc-title"><span>Allergens</span></h3>
             </div>
            </div>
          </div>
        </div>
        <div className="row padding-bottom-100 " style={{color:'#000'}}>
          <div className="container">
            <div className="row ">

            <div className="text_justify">
             <b> 1) Can I request for an allergen free meal in the café?</b><br/>
             An allergen free meal requires an extremely controlled environment which may not be available while cooking a huge number of meals. Although utmost care is taken for all areas of food production, the products prepared in our kitchen may have come into contact with the most common allergens. Employees having food allergies are advised to use their discretion while consuming food prepared in the in-house kitchens. If your allergic reactions are severe, it is advised to consume home cooked food only.
                </div>

              <div className="text_justify mt-4">
             <b> 2) What Is a Food Allergy ?</b><br/>
A food allergy is a condition in which certain foods trigger an abnormal immune response. It’s caused by your immune system wrongly recognizing some of the proteins in a food as harmful. Your body then launches a range of protective measures, including releasing chemicals like histamine, which causes inflammation. For people who have a food allergy, even exposure to very small amounts of the problem food can cause an allergic reaction. Symptoms can occur anywhere from a few minutes after exposure to a few hours later.
                </div> 

                <div className="mt-4">
             {/* <b> 3) Here are the eight most common food allergies.</b><br/> */}
             <b> 3) The Big 8</b><br/>

             <p className="text_justify">Certain food groups account for about 90% of all food allergies worldwide. These food groups are also known as The Big 8. These major allergenic food groups are as follows:<b> milk, eggs, fish, crustacean shellfish, tree nuts, peanuts, wheat, and soybean.</b> </p>
            <p className="text_justify">Allergic consumers may accidentally encounter problem foods as several of the Big 8 are often used in processed products and can be hidden.</p>
            <div className="text-center">
            <img src="./assets/images/allergens_banner-new.jpg" className="img-fluid text-center" style={{width:'30%'}} />
            </div>
            <p className="text_justify mt-4">For further questions regarding food allergens, please reach out to the Café manager.</p>  

          </div> 
 

           

            <div className="mt-4">
             <b> 4) Other Foods</b><br/>
The 8 food allergies outlined above are the most common ones. However, any food can cause an allergy. Other foods people are allergic to include fruits, vegetables and seeds like:<br/>
•	Linseed<br/>
•	Sesame seed<br/>
•	Peach<br/>
•	Banana<br/>
•	Avocado<br/>
•	Kiwi fruit<br/>
•	Passion fruit<br/>
•	Celery<br/>
•	Garlic<br/>
•	Mustard seeds<br/>
•	Aniseed<br/>
•	Chamomile
                </div>


                <div className="mt-4 text_justify">
             <b> 5) Further questions?</b><br/>
             Please reach out to the Café Manager for any further questions regarding food allergens.                </div>
          </div>
          </div> 
      </div>
      </div>
    </>
        ) }
       }
