import React, { Component } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Slider from "react-slick";


class Sustainability extends Component{

  constructor(){
    super();
    this.state={
      banner_img:[],
      base_url:global.BaseUrl,
      assets_url:global.AssetsUrl,
      
    }
  }

  componentDidMount(){
    fetch(`${this.state.base_url}/getBannerById/12`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.log(result);
        this.setState({ banner_img: result.banner_images});
        // this.setState({ headingType: result.data});           
      })
    });
  }


  render() {
    const {banner_img} = this.state;

    var settings = {
      className: "",
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: 'linear',
      adaptiveHeight: true
      
    };

  
   
        return(
            <>

{/* <div className="container-fluid slider_top">
            <div className="row">
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/sustain_one.jpg" alt="" className='bnr_img_30 padding_set' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/sustain_two.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
              <div className="col-md-4 col-12">
                <img src="./assets/images/banner/sustain_three.jpg" alt="" className='bnr_img_30 padding_set mobile_hide' />
              </div>
            </div>
      </div> */}

      <div className="container-fluid slider_top">
            <div className="row">
              {banner_img.map((banner,i)=>{
                return(
                  <div className="col-md-4 col-12">
                <img src={`${this.state.assets_url}/uploads/${banner}`} alt="" className='bnr_img_30 padding_set' />
              </div>
                )
              })}
            </div>
      </div>
             
              <div className="container detail_section">
                 <section className="mt-5 mb-5 child_section">
                  <div className="row p-top p-btm">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <h3 className="title">SUSTAINABILITY</h3>
                            <hr/>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center ">
                            <p className="text_justify">A sustainable food system is a collaborative network that integrates several components in order to enhance a community’s environmental, economic and social well-being. It is built on principles that further the ecological, social and economic values of a community and region.</p>
                            <p className="text_justify">
                            Dining Services actively explores and implements sustainable practices to help improve the environment where we live and work. Here are the characteristics of a sustainable food system:
                            </p>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                    <ul className="list list-unstyled">
                                      <li><i className="fa fa-check-square" aria-hidden="true"></i> Is secure reliable and resilient to climate change and rising energy.</li>
                                      <li><i className="fa fa-check-square" aria-hidden="true"></i> Is energy-efficient </li>
                                      <li><i className="fa fa-check-square" aria-hidden="true"></i> Is healthy and safe </li>
                                      <li><i className="fa fa-check-square" aria-hidden="true"></i> Is environmentally beneficial </li>
                                      </ul>
                                    </div>
                                </div>
                              </div>                           
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                    <ul className="list list-unstyled mb-20 p-2">
                                      <li> <i className="fa fa-check-square" aria-hidden="true"></i> Leverages local capacity</li>
                                      <li> <i className="fa fa-check-square" aria-hidden="true"></i> Is celebrated through events, promotions, etc. </li>
                                      <li> <i className="fa fa-check-square" aria-hidden="true"></i> Has a strong educational focus to create awareness </li>
                                      <li> <i className="fa fa-check-square" aria-hidden="true"></i> Is fairly traded </li>
                                    </ul>
                                    </div>
                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
                  </section>
                  
                  <section className="mt-5 mb-5 child_section" >
                  <div className="row p-top p-btm">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <h3 className="title">We Are Committed To These Sustainable Practices </h3>
                            <hr/>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="row ">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                      <h5 className="sustitle">SUSTAINABLE FOOD</h5>
                                    <ul className="list list-unstyled mb-20 mt-10 p-2">
                                      <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> <b > Local Produce :</b> We have partnered with our suppliers to offer you fresh, local, and in-season produce. </li>
                                      <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> <b >Cooking Practices :</b> We closely monitor all cooking processes to ensure best practices. We have also restricted usage of certain ingredients like Mono Sodium Glutamate (MSG), Palmolein Oil, Food Colours, etc. </li>
                                      </ul>
                                    </div>
                                </div>
                              </div>                           
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top ">
                                <div className="card">
                                    <div className="card-body">
                                    <h5 className="sustitle">WASTE REDUCTION & RECYCLING</h5>
                                    <ul className="list list-unstyled mb-20 mt-10 p-2">
                                        <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> Efficient water filtration machines placed in most of the cafe areas to reduce reliance on packaged water and use of plastic. </li>
                                        <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> Elimination of disposable plastic. </li>
                                        <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> Encouraging suppliers to reduce, reuse and recycle. </li>
                                        <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> Reduction of paper cups and Adoption of ecofriendly packaging</li>
                                        
                                    </ul>
                                    </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                      <h5 className="sustitle">WATER EFFICIENCY</h5>
                                      <ul className="list list-unstyled mb-20 mt-10 p-2">
                                        <li className="text_justify"> <i className="fa fa-check-square" aria-hidden="true"></i> Modern, conveyer belt-type dishwashing machines present in all sites to ensure efficient use of water, chemicals and energy. </li>
                                        </ul>
                                    </div>
                                </div>
                              </div> 
                              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                      <h5 className="sustitle">ENERGY EFFICIENCY</h5>
                                      <ul className="list list-unstyled mb-20 mt-10 p-2">
                                        <li> <i className="fa fa-check-square" aria-hidden="true"></i> Energy-efficient equipment used in all areas. </li>
                                        <li> <i className="fa fa-check-square" aria-hidden="true"></i> Proactively explore other means / ways to help reduce consumption. </li>
                                        </ul>
                                    </div>
                                </div>
                              </div> 

                              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  m-top">
                                <div className="card">
                                    <div className="card-body">
                                      <h5 className="sustitle">KEY CONSIDERATIONS</h5>
                                      <ul className="list list-unstyled mb-20 mt-10 p-2">
                                        <li> <i className="fa fa-check-square" aria-hidden="true"></i> Elimination of paper cups and plastic products  </li>
                                        <li> <i className="fa fa-check-square" aria-hidden="true"></i> Adoption of eco friendly packaging  </li>
                                        <li> <i className="fa fa-check-square" aria-hidden="true"></i> Emphasis on sourcing of local products </li>

                                        </ul>
                                    </div>
                                </div>
                              </div>  */}

                            </div>
                      </div>
                  </div>
                  </section>
              </div>
            </>
        );
    }
}

export default Sustainability;
