import React,{Component} from 'react';
import './BaseUrl';
import './Style.css';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
class CounterMenu extends Component {


    constructor(){
        super();
        this.state={
          counter_data:[],
      assets_url:global.AssetsUrl,
         counter_details:[],
         counter_images:[],
          headingType:[],
          my_id:[],
          base_url:global.BaseUrl,
          
        }
      }
    
       
    
      componentDidMount(){

        window.scrollTo(0, 0);
     
        $(document).ready(function(){
	
          $('ul.tabs li').click(function(){
            var tab_id = $(this).attr('data-tab');
        
            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');
        
            $(this).addClass('current');
            $("#"+tab_id).addClass('current');
          })
        
        });
    
       

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            
            this.setState({ counter_details: result.counter});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            
            this.setState({ counter_data: result.counter_data});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            
            this.setState({ headingType: result.counter_data});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            
            this.setState({ counter_images: result.counter_images});
            // this.setState({ headingType: result.data});           
          })
        });
        
     

    }

    render(){
        const {counter_data,headingType,counter_details,counter_images} = this.state;
    
     
        return (
          <>
            <section className="about-us-session padding-top-100">
              <div className="container-fluid">
                {}
                <div className="row">
                  <div className="col-md-3 col-12 counter_top_img">
                    <img
                      src={`${this.state.assets_url}/uploads/${counter_details.counter_logo}`}
                      alt
                      className="counter_top_content img img-responsive wow zoomIn"
                      style={{ visibility: "visible", animationName: "zoomIn" }}
                    />
                  </div>

                  {counter_images.map((image) => {
                    return (
                      <div className="col-md-3 col-12  counter_top_img menu_img">
                        <img
                          src={`${this.state.assets_url}/uploads/${image}`}
                          alt
                          className="counter_top_content img img-responsive wow zoomIn counter_img"
                          style={{
                            visibility: "visible",
                            animationName: "zoomIn",
                            maxHeight: "212px !important",
                            width: "100%",
                          }}
                        />
                      </div>
                    );
                  })}

                  <div className="col-md-3 col-12  counter_top_img ">
                    {/* <p>Menu Indicator</p> */}
                    <div
                      className="counter_top_content"
                      style={{
                        background: "#fff",
                        padding: "0px 10px 0px 10px",
                        border: "1px solid #48368e",
                        boxShadow: "0.5rem 1rem rgba(0,0,0,.15)!important",
                      }}
                    >
                      <ui>
                        <div style={{ textAlign: "center" }}>
                          <b>Menu Indicator</b>
                        </div>
                        {/* <div><span><img src="../assets/images/icon/vag.png" width="20px" /></span> <span>Vegetarian Preparation</span></div>
                <div><span><img src="../assets/images/icon/non-vag-new.png" width="20px" /></span> <span>Non Vegetarian Preparation</span></div> */}
                        <div>
                          <span>
                            <img
                              src="../assets/images/icon/1.png"
                              width="20px"
                            />
                          </span>{" "}
                          <span>Vegetarian Preparation</span>
                        </div>
                        {/* <div><span><img src="../assets/images/icon/Healthy.jpg" width="20px" /></span> <span>Health Focused Preparation</span></div> */}
                        <div>
                          <span>
                            <img
                              src="../assets/images/icon/2.png"
                              width="20px"
                            />
                          </span>{" "}
                          <span>Non Vegetarian Preparation</span>
                        </div>
                        <div>
                          <span>
                            <img
                              src="../assets/images/icon/3.png"
                              width="20px"
                            />
                          </span>{" "}
                          <span>Contains fish or their products</span>
                        </div>
                        {/* <div><span><img src="../assets/images/icon/Spicy.jpg" width="20px" /></span> <span>Spicy</span></div> */}
                        <div>
                          <span>
                            <img
                              src="../assets/images/icon/10.png"
                              width="20px"
                            />
                          </span>{" "}
                          <span>Little Green Fork</span>
                        </div>
                      </ui>
                    </div>
                  </div>

                  {/* <div className="col-md-1"></div> */}
                </div>
              </div>
            </section>

            {headingType.map((heading) => {
              // console.log("hello" , heading.id);
              this.state.my_id = heading.id;
              return <h1></h1>;
            })}

            {this.state.my_id == 8 ? (
              <section
                className="container my_div padding-top-50"
                style={{ marginTop: "50px" }}
              >
                <div className="swin-sc swin-sc-title  light">
                  <p className="top-title">
                    <span>All Day</span>
                  </p>
                  <h3 className="title" style={{ color: "#000" }}>
                    Weekly Special
                  </h3>
                </div>
                {counter_data.map((mydata) => {
                  const category_list = mydata.categories;
                  return (
                    <div className="text-left">
                      {category_list.map((supercat_name) => {
                        const sub_catategory = supercat_name.subcategories;

                        return (
                          <div style={{ padding: "10px", marginTop: "20px" }}>
                            <h1 style={{ color: "gray", textAlign: "center" }}>
                              {supercat_name.supercatname}
                            </h1>
                            {sub_catategory.map((sub_cat) => {
                              const dishes = sub_cat.category_dishes;
                              return (
                                <div
                                  style={{
                                    background: "#fff",
                                    padding: "10px",
                                  }}
                                >
                                  {/* <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> */}

                                  {sub_cat.subcatname == "NA" ? (
                                    <h3></h3>
                                  ) : (
                                    <h3 className="sub_cat_heading">
                                      {sub_cat.subcatname}
                                    </h3>
                                  )}
                                  <div className="container">
                                    <div className="row">
                                      {dishes.map((dish_list) => {
                                        return (
                                          <div
                                            className="col-lg-6 col-md-6"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                              <div>
                                                <div>
                                                  {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}

                                                  {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                  <p
                                                    className="dish_name"
                                                    style={{
                                                      float: "left",
                                                      marginRight: "5px",
                                                    }}
                                                  >
                                                    {dish_list.dish_name}{" "}
                                                    <span>
                                                      {dish_list.dish_indicator1 ==
                                                      null ? null : (
                                                        <div className="indicator_icon">
                                                          {" "}
                                                          <span>
                                                            <img
                                                              src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                              className="dish_icon indicator_img"
                                                              id="new_tab"
                                                              style={{
                                                                marginLeft:
                                                                  "5px",
                                                                marginTop:
                                                                  "0px",
                                                              }}
                                                            />{" "}
                                                          </span>{" "}
                                                        </div>
                                                      )}
                                                      {dish_list.dish_indicator2 ==
                                                      null ? null : (
                                                        <div className="indicator_icon">
                                                          <span>
                                                            <img
                                                              src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                              className="dish_icon"
                                                              style={{
                                                                marginLeft:
                                                                  "5px",
                                                                marginTop:
                                                                  "0px",
                                                              }}
                                                            />
                                                          </span>{" "}
                                                        </div>
                                                      )}
                                                    </span>
                                                    {/* <div className="indicator_icon_text">tootip</div>  */}
                                                  </p>
                                                  {/* <button type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
  Tooltip on top
</button>
                                 */}
                                                  <p className="item-right dish_name text-center">
                                                    {dish_list.dish_price ==
                                                    null ? null : (
                                                      <span className="price woocommerce-Price-amount amount">
                                                        ₹{dish_list.dish_price}
                                                      </span>
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="des"
                                              style={{ lineHeight: "0.1" }}
                                            >
                                              {dish_list.dish_description}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </section>
            ) : (
              <section
                className="my_div padding-top-50"
                style={{ marginTop: "50px" }}
              >
                <div className="swin-sc swin-sc-title text-center light ">
                  <p className="top-title">
                    <span>Weekly Change</span>
                  </p>
                  <h3 className="title" style={{ color: "#000" }}>
                    Daily Special
                  </h3>
                </div>

                <div className="container">
                  <div className="row">
                    <section className="about-us-session padding-top-100 padding-bottom-100 container">
                      <div className="container ">
                        <div
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <ul className="tabs">
                            <li
                              className="tab-link tabs-btn current active swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Monday"
                            >
                              Monday
                            </li>
                            <li
                              className="tab-link tabs-btn swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Tuesday"
                            >
                              Tuesday
                            </li>
                            <li
                              className="tab-link tabs-btn swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Wednesday"
                            >
                              Wednesday
                            </li>
                            <li
                              className="tab-link tabs-btn swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Thursday"
                            >
                              Thursday
                            </li>
                            <li
                              className="tab-link tabs-btn swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Friday"
                            >
                              Friday
                            </li>
                            <li
                              className="tab-link tabs-btn swin-btn"
                              style={{ float: "left", marginLeft: "20px" }}
                              data-tab="Saturday"
                            >
                              Saturday
                            </li>
                            {/*<li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Sunday">Sunday</li> */}
                          </ul>
                        </div>
                        <div id="Monday" className="tab-content current active">
                          {counter_data
                            .filter((mydata) => mydata.name == "Monday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {/* <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> */}
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        1
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>

                        <div id="Tuesday" className="tab-content ">
                          {counter_data
                            .filter((mydata) => mydata.name == "Tuesday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        2
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../avenue/assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../avenue/assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                              <div></div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>

                        <div id="Wednesday" className="tab-content ">
                          {counter_data
                            .filter((mydata) => mydata.name == "Wednesday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        3
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../avenue/assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../avenue/assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>

                        <div id="Thursday" className="tab-content ">
                          {counter_data
                            .filter((mydata) => mydata.name == "Thursday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        4
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../avenue/assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../avenue/assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>

                        <div id="Friday" className="tab-content ">
                          {counter_data
                            .filter((mydata) => mydata.name == "Friday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        5
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../avenue/assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../avenue/assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>

                        <div id="Saturday" className="tab-content ">
                          {counter_data
                            .filter((mydata) => mydata.name == "Saturday")
                            .map((mydata) => {
                              const category_list = mydata.categories;
                              return (
                                <div className="text-left">
                                  {category_list.map((supercat_name) => {
                                    const sub_catategory =
                                      supercat_name.subcategories;

                                    return (
                                      <div
                                        style={{
                                          padding: "10px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <h1
                                          style={{
                                            color: "gray",
                                            textAlign: "center",
                                          }}
                                        >
                                          {supercat_name.supercatname}
                                        </h1>
                                        {sub_catategory.map((sub_cat) => {
                                          const dishes =
                                            sub_cat.category_dishes;
                                          return (
                                            <div
                                              style={{
                                                background: "#fff",
                                                padding: "10px",
                                              }}
                                            >
                                              {sub_cat.subcatname == "NA" ? (
                                                <h3></h3>
                                              ) : (
                                                <h3 className="sub_cat_heading">
                                                  {sub_cat.subcatname}
                                                </h3>
                                              )}
                                              <div className="container">
                                                <div className="row">
                                                  {dishes
                                                    .filter(
                                                      (dish_list) =>
                                                        dish_list.counter_heading ==
                                                        6
                                                    )
                                                    .map((dish_list, index) => {
                                                      return (
                                                        <div
                                                          className="col-lg-6 col-md-6"
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        >
                                                          <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                                            <div>
                                                              <div>
                                                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                                                {/* {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../avenue/assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../avenue/assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                } */}
                                                                <p
                                                                  className="dish_name"
                                                                  style={{
                                                                    float:
                                                                      "left",
                                                                    marginRight:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    dish_list.dish_name
                                                                  }{" "}
                                                                  <span>
                                                                    {dish_list.dish_indicator1 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator1}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                    {dish_list.dish_indicator2 ==
                                                                    null ? null : (
                                                                      <img
                                                                        src={`${this.state.assets_url}/uploads/${dish_list.dish_indicator2}.png`}
                                                                        className="dish_icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                          marginTop:
                                                                            "0px",
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </p>

                                                                <p className="item-right dish_name text-center">
                                                                  {dish_list.dish_price ==
                                                                  null ? null : (
                                                                    <span className="price woocommerce-Price-amount amount">
                                                                      ₹
                                                                      {
                                                                        dish_list.dish_price
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="des"
                                                            style={{
                                                              lineHeight: "0.1",
                                                            }}
                                                          >
                                                            {
                                                              dish_list.dish_description
                                                            }
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            )}

            {/* <section  className="about-us-session padding-top-100 padding-bottom-100 container">
<div className="container">
  <ul className="tabs">
    <li className="tab-link current" data-tab="tab-1">Tab One</li>
    <li className="tab-link" data-tab="tab-2">Tab Two</li>
    <li className="tab-link" data-tab="tab-3">Tab Three</li>
    <li className="tab-link" data-tab="tab-4">Tab Four</li>
    <li className="tab-link" data-tab="tab-5">Tab Five</li>

  </ul>
  <div id="tab-1" className="tab-content current">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
  <div id="tab-2" className="tab-content">
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </div>
  <div id="tab-3" className="tab-content">
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </div>
  <div id="tab-4" className="tab-content">
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
  <div id="tab-5" className="tab-content">
    5o eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div>
  </section> */}

            {/* enter old code */}
          </>
        );
    }
}

export default CounterMenu;