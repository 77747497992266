import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import {Link} from '@material-ui/core';
import Slider from "react-slick";
import MaterialTable from 'material-table';



export default class Tab5 extends Component{
    constructor(){
        super();
        this.state={
          tab5:[],
      banner_img:[],
      Archives:[],
      relatedoc:[],
      Filter_Archives:[],
      model_data:"0",
      from_date:"",
      to_date:"",
      base_url:global.BaseUrl,
      assets_url:global.AssetsUrl,

        }
      }
    
      componentDidMount(){

      
    
        fetch(`${this.state.base_url}/tab5`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ tab5: result});        
          })
        });
        fetch(`${this.state.base_url}/getfilesbyid/13`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ relatedoc: result});        
          })
        });

        fetch(`${this.state.base_url}/getBannerById/13`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result);
            this.setState({ banner_img: result.banner_images});
            // this.setState({ headingType: result.data});           
          })
        });


        fetch(`${this.state.base_url}/getallArchives`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result);
            this.setState({ Archives: result});
            // this.setState({ headingType: result.data});           
          })
        });

        // fetch(`${this.state.base_url}/getByFilter/${this.state.from_date}/${this.state.to_date}`).then((resp)=>{
        //   resp.json().then((result)=>{
        //      console.log(result);
        //     this.setState({ Filter_Archives: result});
        //     // this.setState({ headingType: result.data});           
        //   })
        // });
        this.fetchData();
        // this.Model_Details();
    }

    fetchData = () => {
      fetch(`${this.state.base_url}/getByFilter/${this.state.from_date}/${this.state.to_date}`, {
        method: "GET",
        dataType: "JSON",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      })
      .then((resp) => {
        resp.json().then((result)=>{
         this.setState({ Filter_Archives: result});

        })
      });      
    }

    Model_Details = (id) =>{
  
         this.setState({ model_data: id});
            
    }
    


    render(){

        const {tab5,relatedoc,banner_img,Archives,Filter_Archives,model_data} = this.state;

        
      var settings = {
        className: "",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        cssEase: 'linear',
        adaptiveHeight: true
        
      };
      
     
       return(
        <> 
        
 <div className="slider_images">
        
        <Slider {...settings}>
        {
              banner_img.map(single_image=>{
                return(
                  <div>
                  <img src={`${this.state.assets_url}/uploads/${single_image}`} className="bnr_img" />
                  </div>
                )
              })
            }
         
        </Slider>
      </div>
<section className="section-reservation-form padding-bottom-100 ">
              <div className="container">
                {
                    tab5.map(page_name=>
                        <div className="swin-sc swin-sc-title style-2">
                        <h3 className="title swin-sc-title"><span>{page_name.sub_page}</span></h3>
                      </div>
                        )
                }
                  </div>

                <div className="container mt-4">
                  <div className="row">
                    <div className="col-md-2">
                      <label style={{marginLeft:'5px'}}>Start Date</label>
                      <input className="form-control"  placeholder="From Date" type="date" value={this.state.from_date} name="from_date" onChange={e => this.setState({ from_date: e.target.value })}/>
                    </div>

                    <div className="col-md-2">
                    <label style={{marginLeft:'5px'}}>End Date</label>
                      <input className="form-control" placeholder="To Date" type="date" value={this.state.to_date} name="to_date" onChange={e => this.setState({ to_date: e.target.value })}/>
                    </div>

                    <div className="col-md-2">
                    <button className="btn btn-primary" style={{marginTop:"30px"}} onClick={this.fetchData}>Get Data</button>

                    </div>
                  </div>
                </div>

                {/* <h1>{this.state.model_data}</h1> */}


            <div className="container mt-4">
              <div className="row table-wrapper-scroll-y my-custom-scrollbar">
                 <table id="customers">
                   <thead style={{position:'sticky'}}>
                     <tr>
                       <th>Title</th>
                       <th>Post Date</th>
                       {/* <th>View</th> */}
                     </tr>
                   </thead>
                   <tbody className=" table table-bordered table-striped mb-0">
                    
                       {

                         this.state.from_date == ""?
                         Archives.map((archive)=>{
                          return(
                            <tr type="button" onClick={() => this.Model_Details(archive.id)}  data-toggle="modal" data-target="#exampleModal" style={{cursor:'pointer'}}>
                              <td>{archive.title}</td>
                            <td>{archive.posted_at}</td>
                            
                           
                            </tr>
                          )
                        })
                        :
                        Filter_Archives.map((filter_archive)=>{
                          return(
                            <tr type="button" onClick={() => this.Model_Details(filter_archive.id)}  data-toggle="modal" data-target="#exampleModal" style={{cursor:'pointer'}}>
                              <td>{filter_archive.title}</td>
                            <td>{filter_archive.posted_at}</td>
                           
                            </tr>
                          )
                        })
                    
                       }
                     
                   </tbody>
                 </table>
              </div>
            </div>


         


            {
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`${this.state.assets_url}/uploads/${doc.file_name}`} target="_blank"><p className="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }
      
            </section>

              {/* //model */}

              <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
      {
          Archives.filter(image=>image.id == this.state.model_data).map(image=>{
            return (
              <h5 className="modal-title" style={{textAlign:'center'}} id="exampleModalLabel">{image.title}</h5>
            )
          })
        }
        {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> */}
      </div>
      <div className="modal-body">
        {
          Archives.filter(image=>image.id == this.state.model_data).map(image=>{
            return (
              <img src={`${this.state.assets_url}/uploads/${image.image}`} style={{width:"100%"}} />
            )
          })
        }
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>
            
    </>

) }

     
       }
